import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CalendarResponseMessage,
  MeetingChatMessage,
} from "../server";
import { AppThunk } from "./store";
import calendarInterface from "./calendarInterface";
import { showMessage } from "./alertSlice";
import { RESPONSE_CODE } from "../helpers/asnycRequest";
import axios from 'axios';

interface ChatState extends CalendarResponseMessage {
    chat_messages: Array<MeetingChatMessage>;
}

const initialState: ChatState = {
  chat_messages: []
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChatMessages(state, action: PayloadAction<CalendarResponseMessage>) {
      state.chat_messages = action.payload.chat_messages || [];
    },
    appendChatMessages(state, action: PayloadAction<MeetingChatMessage[]>) {
      state.chat_messages = [...state.chat_messages, ...action.payload]
    }
  },
});

export const {
  setChatMessages,
  appendChatMessages,
} = chatSlice.actions;
export default chatSlice.reducer;


export const getMeetingChatLog = (meetingId: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    const { usertoken } = state.auth;
    const user_id = state.auth.user_id;
    if (!usertoken || !user_id) return;

    let resp: CalendarResponseMessage;
    if (meetingId !== "") {
      resp = await calendarInterface.getChatMessages(usertoken, meetingId);
    } else {
      resp = await calendarInterface.getPagedChatHistoryForUser(usertoken, user_id);
    }
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setChatMessages(resp));
    } else {
      dispatch(showMessage(resp.message || "something went wrong", "warning"));
    }
  } catch (err) {
    dispatch(showMessage(err?.message??'error', "danger"));
  }
};

export const getChatHistory = (clientId: string, page = 0): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    const { usertoken } = state.auth;
    if (!usertoken) return;

    const resp: CalendarResponseMessage = await calendarInterface.getPagedChatHistoryForUser(
      usertoken,
      clientId,
      page
    );
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setChatMessages(resp));
    } else {
      dispatch(showMessage(resp.message || "something went wrong", "warning"));
    }
  } catch (err) {
    dispatch(showMessage(err?.message??'error', "danger"));
  }
};

export const sendChatMessage = (
  chatObj: MeetingChatMessage, file?: File
): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, user_id } = state.auth;
    if (!usertoken || !user_id) return;

    if (chatObj.message_type === 'file' && file && chatObj.client_id) {
      chatObj.file_name = file.name;
    }

    const resp: CalendarResponseMessage = await calendarInterface.sendChatMessage(
      usertoken,
      chatObj
    );

    if (resp.code === RESPONSE_CODE.SUCCESS) {
      if(file && resp.chat_messages && resp.chat_messages.length > 0 && resp.chat_messages[0].url) {
        const formData = new FormData();
        formData.append('myFile', file, file.name);
        axios.put(resp.chat_messages[0].url, file);
      }

      // fetch chat messages
     //   dispatch(getMeetingChatLog(chatObj.meeting_id ?? ''));
    } else {
      dispatch(showMessage(resp.message || "something went wrong", "warning"));
    }
  } catch (err) {
    dispatch(showMessage(err?.message??'error', "danger"));
  }
};

export const deleteMeetingChatMessage = (
  meetingId: string,
  messageId: string
): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken } = state.auth;
    if (!usertoken) return;

    const resp: CalendarResponseMessage = await calendarInterface.deleteMeetingChatMessage(
      usertoken,
      messageId
    );
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getMeetingChatLog(meetingId));
    } else {
      dispatch(showMessage(resp.message || "something went wrong", "warning"));
    }
  } catch (err) {
    dispatch(showMessage(err?.message??'error', "danger"));
  }
};

export const updateMeetingChatMessage = (
  meetingId: string,
  chatObj: MeetingChatMessage
): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken } = state.auth;
    if (!usertoken) return;

    const resp: CalendarResponseMessage = await calendarInterface.updateMeetingChatMessage(
      usertoken,
      chatObj
    );
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getMeetingChatLog(meetingId));
    } else {
      dispatch(showMessage(resp.message || "something went wrong", "warning"));
    }
  } catch (err) {
    dispatch(showMessage(err?.message??'error', "danger"));
  }
};
