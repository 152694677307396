import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationUser } from 'server';
import { updateUserStatus } from './accountService';
import { showMessage } from "./alertSlice";
import { RESPONSE_CODE } from './shared';

import { AppThunk } from './store';

export interface AccountState {
  user: OrganizationUser | null,
  status?: 'idle' | 'in a meeting',
}

const initialState: AccountState = {
    user: null,
    status: 'idle'
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
      setUser: (state, action : PayloadAction<OrganizationUser>) => {
        // TODO: change this to `state.user = action.payload;`
        state.user = {
            first_name: action.payload.first_name,
            last_name: action.payload.last_name,
            email: action.payload.email,
            user_id: action.payload.user_id,
            active: action.payload.active,
            pref_lang: action.payload.pref_lang,
            social_sec_no: action.payload.social_sec_no,
            phone: action.payload.phone,
        };
      },
      setStatus: (state, action: PayloadAction<'idle' | 'in a meeting'>) => {
        state.status = action.payload;
      }
    }
});

export const setUserStatus = (status: 'idle' | 'in a meeting'): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.auth.usertoken;
    if (!authToken) return;
  
    if (state.account.status === status) {
      return;
    }
  
    const resp = await updateUserStatus(authToken, status);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setStatus(status));
    }
  } catch (err) {
    dispatch(showMessage(err, "danger"));
  }
};

export const { setUser, setStatus } = accountSlice.actions;
export default accountSlice.reducer;

