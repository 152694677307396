export const RESPONSE_CODE = {
    SUCCESS: 1000,
    ERROR: -1,
    EXCEPTION: -100,
  };

export function handleErrorResponse(e: any) {
    const error =
      (e.response && e.response.data) ||
      e.message ||
      e.statusText;
    if (error) {
      return Promise.reject(error);
    }
    return Promise.reject();
  }

//   function handleErrorResponse(e: any) {
//     const error = (e.response && e.response.data && e.response.data.message) || e.message || e.statusText;
//     if (error) {
//       return Promise.reject(error);
//     }
  
//     return Promise.reject();
//   }
  