import { RegisterOptions } from "react-hook-form";

export const serverSideValidationErrors: { [key: string]: string } = {
  user_exist: "Sie haben sich bereits bei uns registriert, bitte",
};

export const redBorder = { borderColor: "red" };

export const validations: ValidationTypes = {
  email: {
    required: "Dieses Feld ist erforderlich",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Ungültige E-Mail-Adresse",
    },
  },
  password: {
    required: "This field is required",
    minLength: { value: 8, message: "Mindestens 8 Zeichen." },
    maxLength: { value: 24, message: "Maximal 24 Zeichen." },
  },
  full_name: {
    required: "Dieses Feld ist erforderlich",
    minLength: { value: 2, message: "Mindestens 2 Zeichen." },
    maxLength: { value: 50, message: "Maximal 50 Zeichen." },
  },
  first_name: {
    required: "Dieses Feld ist erforderlich",
    minLength: { value: 2, message: "Mindestens 2 Zeichen." },
    maxLength: { value: 30, message: "Maximal 30 Zeichen." },
  },
  last_name: {
    required: "Dieses Feld ist erforderlich",
    minLength: { value: 2, message: "Mindestens 2 Zeichen." },
    maxLength: { value: 30, message: "Maximal 30 Zeichen." },
  },
  passcode: {
    required: "Dieses Feld ist erforderlich",
    minLength: { value: 2, message: "Mindestens 2 Zeichen." },
    maxLength: { value: 50, message: "Maximal 50 Zeichen." },
  },
  phone_number: {
    required: "Dieses Feld ist erforderlich",
    min: { value: 1000000, message: "Bitte geben sie ihre Telefonnummer ein" },
    max: {
      value: 999999999999,
      message: "Bitte geben Sie die richtige Telefonnummer ein!",
    },
  },
  social_security_number: {
    pattern: {
      value: /^\d{10}$/i,
      message: "Ungültige soziale Nummer",
    },
  },
  mandatory_social_security_number: {
    required: "Dieses Feld ist erforderlich",
    pattern: {
      value: /^\d{10}$/i,
      message: "Sie müssen eine Sozialversicherungsnummer haben, um unseren Service zu nutzen",
    },
  },
  phone_or_email: {
    required: "Dieses Feld ist erforderlich",
    pattern: {
      value: /^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})|(\+\d{9,15})+$/i,
      message:
        "E-Mail Adresse oder Mobiltelefonnummer, mit der Sie bei uns registriert sind. Noch nicht registriert?",
    },
  },
  phone_login: {
    required: "Dieses Feld ist erforderlich",
    pattern: {
      value: /^(\([0-9]{3}\) | [0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
      message: "Ungültige Telefonnummer",
    },
  },
  note: {
    required: "Dieses Feld ist erforderlich",
    minLength: { value: 0, message: "Mindestens 2 Zeichen." },
    maxLength: { value: 100, message: "Maximal 100 Zeichen." },
  },
};

export type ValidationTypes = {
  email: RegisterOptions;
  password: RegisterOptions;
  first_name: RegisterOptions;
  last_name: RegisterOptions;
  full_name: RegisterOptions;
  passcode: RegisterOptions;
  phone_number: RegisterOptions;
  social_security_number: RegisterOptions;
  mandatory_social_security_number: RegisterOptions;
  phone_or_email: RegisterOptions;
  phone_login: RegisterOptions;
  note: RegisterOptions;
};
