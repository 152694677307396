import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthenticationResponse } from '../server';

import { AppThunk } from './store';

import { authenticateUserOTP, authenticateClientOTP } from './authService';
import { RESPONSE_CODE } from './shared';
import { calendarSlice } from './calendarSlice';
import {alertSlice} from './alertSlice';
import { accountSlice } from './accountSlice';
import { orgSlice } from './orgSlice';

export interface AuthState {
  cameFromLogin: boolean,
  identifierType: 'phone_passcode' | 'email_passcode' 
  email: string;
  phoneNumber: string;
  countryCode: string;
  usertoken: string;
  note: string;
  user_id: string;
  remember: boolean;
  userLoggedIn: boolean;
}

const initialState : AuthState = {
  cameFromLogin: false,
  identifierType: 'email_passcode',
  email: '',
  phoneNumber: '',
  countryCode: '',
  usertoken: '',
  note: '',
  user_id: '',
  remember: false,
  userLoggedIn: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      setRegisterInfo (state, action : PayloadAction<{cameFromLogin: boolean, email: string, phoneNumber: string, identifierType: 'phone_passcode' | 'email_passcode', countryCode: string, remember?: boolean }>){
        state.cameFromLogin = action.payload.cameFromLogin;
        state.phoneNumber = action.payload.phoneNumber;
        state.email = action.payload.email;
        state.identifierType = action.payload.identifierType;
        state.countryCode = action.payload.countryCode;
        if(action.payload.remember) {
          state.remember = action.payload.remember;
        }
      },
      setUserAuth (state, action: PayloadAction<AuthenticationResponse>){
        state.usertoken = action.payload.usertoken? `Bearer ${action.payload.usertoken}`: '';
        state.user_id = action.payload.userid??'';
        if (action.payload.meeting_request && action.payload.meeting_request?.length > 0) {
          // TODO: Check if the requests are properly ordered
          state.note = action.payload.meeting_request[0].note??'';
        }
        state.userLoggedIn = true;
      },
      resetAuth() {
        return initialState;
      },
      resetToken(state) {
        state.usertoken = '';
      },
      resetUserId(state) {
        state.user_id = '';
      },
      setUserLoggedIn(state: AuthState, action: PayloadAction<boolean>) {
        state.userLoggedIn = action.payload;
      },
    }

})

export const validatePasscode = (passcode: string, remember?: boolean): AppThunk => async (dispatch, getState, ) => {
  try {
    const state = getState();
    const cameFromLogin = state.auth.cameFromLogin;
    let res: AuthenticationResponse;
    if (cameFromLogin) {
      res = await authenticateUserOTP(passcode, remember);
    } else {
      res = await authenticateClientOTP(passcode);
    }

    if (res.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setUserAuth(res));

      if(res.organization_user) {
        dispatch(accountSlice.actions.setUser(res.organization_user))
      }
      if(res.organization_data) {
        dispatch(orgSlice.actions.setOrgData(res.organization_data))
      }
      if (res.meeting_request) {
        dispatch(calendarSlice.actions.setMeetingRequests(res.meeting_request));
      }

      dispatch(alertSlice.actions.setAlertData({alertType: 'success', title: 'Vielen Dank!', text: 'Ihre Eingabe wurde bestätigt.' }));

    } else {
      dispatch(alertSlice.actions.setAlertData({alertType: 'error', title: 'Fehler', text: 'Fehler. ' + res.message??'' }));
    }
  } catch (err) {
    dispatch(alertSlice.actions.setAlertData({alertType: 'error', title: 'Fehler', text: 'Bitte geben Sie den Code ein, den wir Ihnen per E-Mail/Telefon (je nach Ihrer Wahl) zugesandt haben.' }));
  }
};

export const { setRegisterInfo, setUserAuth, resetAuth, setUserLoggedIn, resetToken, resetUserId} = authSlice.actions;
export default authSlice.reducer;