import React from "react";
import { IonInput, IonLabel, IonText, IonTextarea } from "@ionic/react";
import { useFormContext } from "react-hook-form";
import {
  redBorder,
  ValidationTypes,
  validations,
} from "helpers";

export interface InputProps {
  name: string;
  label: string;
  inputClassName: string;
  type: "tel" | "email" | "text";
  refName: keyof ValidationTypes;
  lines?: "TextArea" | "Singleline";
  required?: boolean;
  placeholder?: string;
  serverErrorsToClean?: string[];
  maxLength?: number;
  pattern?: string;
  hidden?: boolean;
}

export const MPInputText: React.FC<InputProps> = ({
  children,
  name,
  label,
  type,
  inputClassName,
  lines = "Singleline",
  required,
  placeholder,
  serverErrorsToClean,
  maxLength,
  refName,
  pattern,
  hidden,
}) => {
  const {
    clearErrors,
    register,
    formState: { errors },
  } = useFormContext();

  const refRule = validations[refName];

  // override the validation required in case it isn't needed. 
  if (required === false) {
    refRule['required'] = false;
  }

  function setAutoGrow() {
    const inputElement = document.querySelector(".note-textbox");
    inputElement?.setAttribute("auto-grow", "true");
  }

  return (
    <>
      <IonLabel
        color={errors?.[name] && "danger"}
        className={"input-label label-emphasis"}
      >
        {label} {required && <sup>*</sup>}
      </IonLabel>

      {children}

      {lines === "Singleline" ? (
        !hidden && (
        <IonInput
          aria-invalid={errors.name ? "true" : "false"}
          style={errors?.[name] && redBorder}
          type={type}
          placeholder={placeholder}
          maxlength={maxLength && maxLength}
          onClick={
            !!serverErrorsToClean
              ? () => clearErrors(serverErrorsToClean)
              : undefined
          }
          {...register(name, refRule)}
          name={name}
          class={inputClassName}
          pattern={pattern}
          required={required}
        ></IonInput>)
      ) : (
        <IonTextarea
          style={errors?.[name] && redBorder}
          onClick={() => clearErrors(serverErrorsToClean)}
          class={inputClassName}
          rows={4}
          onIonFocus={() => {
            setAutoGrow();
          }}
          {...register(name, refRule)}
          name={name}
          placeholder={placeholder}
        ></IonTextarea>
      )}
      {errors?.[name]?.message && (
        <IonText color="danger">
          <small>{errors?.[name]?.message}</small>
        </IonText>
      )}
    </>
  );
};