import { configureStore, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ThunkAction } from "redux-thunk";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist';
  
import storage from 'redux-persist/lib/storage';

import rootReducer, { RootState } from './rootReducer';


const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['account', 'auth', 'org']
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

const rootStore = configureStore({
    reducer: persistedReducer,
    // middleware: [thunk]
    middleware: getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),    
})
let persistor = persistStore(rootStore);

export type AppDispatch = typeof rootStore.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export {
    rootStore,
    persistor,
};
