import React from "react";
import { useSelector } from 'react-redux';
import { IonHeader, IonImg } from "@ionic/react";
import { RootState } from "redux/rootReducer";
import './Header.scss';
import {Link} from "react-router-dom";

export const Header: React.FC = () => {
    const orgData = useSelector((state: RootState) => state.org.data);

    return (
    <IonHeader className="header-wrapper">
        <Link to={orgData.home_path??"/home"} >
            <IonImg className="org-logo" src={orgData?.logo_url} alt={orgData.org_name} />
        </Link>
    </IonHeader>
  );
};