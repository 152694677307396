import { PayloadAction } from '@reduxjs/toolkit';

export interface AsyncRequest {
  loading: boolean;
  requestId?: string;
  error: string | null;
}

export const RESPONSE_CODE = {
  SUCCESS: 1000,
  AUTH_ERROR: -2000,
  ERROR: -1,
  EXCEPTION: -100,
};

export function startLoading(state: AsyncRequest) {
  state.loading = true;
  state.error = null;
}

export function loadingFailed(state: AsyncRequest, action: PayloadAction<string>) {
  state.loading = false;
  state.error = action.payload;
}
