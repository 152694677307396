import React, { createContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/rootReducer";
import { appendChatMessages, getMeetingChatLog } from "redux/chatSlice";
import { getClientRequests } from "redux/calendarSlice";
import { useImmer } from "use-immer";
import { MeetingChatMessage } from "server";
import { BASE_PATH } from "server/base";

type Message = {
  action: string;
  message: string;
  chat_message?: MeetingChatMessage;
  target?: {
    room_id: string;
  };
  sender?: {
    org_id: string;
    user_id: string;
  };
};

type WSType = {
  socket: WebSocket | null;
  socketReady: () => boolean;
  sendMessage: (roomId: string, chatMessage: MeetingChatMessage) => void;
  fetchMessages: (roomId: string) => void;
  joinRoom: (roomId: string, status: string) => void;
  leaveRoom: (roomId: string) => void;
  sendRequest: (roomId: string, requestType: string) => void;
};

const WebSocketContext = createContext<WSType | null>(null);

export { WebSocketContext };

const WebSocketContextComponent = ({ children }: any) => {
  const socketURL = BASE_PATH.replace("http", "ws") + "/data/ws?usertoken=";
  const { usertoken, user_id } = useSelector((state: RootState) => state.auth);
  let [maxConnTryout, setMaxConnTryout] = useState<number>(30);
  let [connTryoutInterval, setConnTryoutInterval] = useState<number>(250);
  let [socket, setSocket] = useState<WebSocket | null>(null);
  let [ws, setWS] = useImmer<WSType | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const connect = () => {
      console.log("in connect: " + socket);
      setSocket(new WebSocket(socketURL + usertoken));
    };

    if (usertoken) {
      if (!socket) {
        connect();
      } else {
        setWS((draft) => {
          if (draft) {
            draft.socket = socket;
          }
        });

        socket.onclose = () => {
          console.log("ws closed");
          if (maxConnTryout === 0) {
            return;
          }
          setTimeout(connect, 1000 + connTryoutInterval);
          setConnTryoutInterval(() => 2 * connTryoutInterval);
          setMaxConnTryout(() => maxConnTryout--);
        };

        socket.onopen = () => {
          console.log("ws opened");
        };

        socket.onmessage = (ev: MessageEvent) => {
          try {
            const msgs: string[] = ev.data.split("\n");
            msgs.forEach((msg) => {
              const payload: Message = JSON.parse(msg);

              console.log("received msg: ", payload);

              switch (payload.action) {
                case "send-message":
                  // When another user in the chat is sending a message
                  if (payload.chat_message) {
                    let chatMsg: MeetingChatMessage = payload.chat_message;
                    dispatch(appendChatMessages([chatMsg]));
                  }
                  break;

                case "fetch-messages":
                  // When another user sends a file message, request to fetch it all.
                  dispatch(getMeetingChatLog(""));
                  break;

                case "fetch-requests":
                  // Forces fetching the requests
                  dispatch(getClientRequests(user_id));
                  break;
              }
            });
          } catch (err) {
            console.log(err, ev.data);
          }
        };
      }
    }
  }, [
    socket,
    socketURL,
    usertoken,
    dispatch,
    setWS,
    user_id,
    connTryoutInterval,
    maxConnTryout,
  ]);

  const sendPayload = (payload: Message) => {
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      return false;
    }

    socket.send(JSON.stringify(payload));
    return true;
  };

  const sendMessage = (roomId: string, chatMessage: MeetingChatMessage) => {
    const payload: Message = {
      target: { room_id: roomId },
      message: chatMessage.text ?? "",
      chat_message: chatMessage,
      action: "send-message",
    };

    return sendPayload(payload);
  };

  const fetchMessages = (roomId: string) => {
    const payload: Message = {
      target: { room_id: roomId },
      message: roomId,
      action: "fetch-messages",
    };

    return sendPayload(payload);
  };

  const sendRequest = (roomId: string, requestType: string) => {
    const payload: Message = {
      target: { room_id: roomId },
      message: requestType,
      action: "send-request",
    };

    return sendPayload(payload);
  };

  const joinRoom = (roomId: string, status: string) => {
    console.log("sending join-room");

    const payload: Message = {
      target: { room_id: roomId },
      message: status,
      action: "join-room",
    };

    return sendPayload(payload);
  };

  const leaveRoom = (roomId: string) => {
    console.log("leaving room ", roomId);
    if (!roomId) {
      return;
    }

    const payload: Message = {
      message: roomId,
      action: "leave-room",
    };

    return sendPayload(payload);
  };

  if (!ws) {
    ws = {
      socket: socket,
      socketReady: () => {
        return socket !== null && socket.readyState === WebSocket.OPEN;
      },
      fetchMessages,
      sendMessage,
      joinRoom,
      leaveRoom,
      sendRequest,
    };
  }

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export default WebSocketContextComponent;
