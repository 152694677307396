import {
  IonContent,
  IonSelect,
  IonSelectOption,
  IonPage,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonText,
  IonItem,
  IonLabel,
} from '@ionic/react';
import React, {useState, useRef, useEffect} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
import './register.scss';

import countryCodes from '../../static/countryCodes.json';
import { Alert, Header, MPInputText } from 'components';
import { clientSignUp } from '../../redux/accountService';
import { setUser } from '../../redux/accountSlice';
import { setRegisterInfo } from '../../redux/authSlice';
import { useDispatch } from 'react-redux';
import {setAlertData, setAlertActive, AlertState} from '../../redux/alertSlice';
import { addServerErrors } from "../../helpers/utils";
import {redBorder, serverSideValidationErrors, validations} from "../../helpers/validations";

interface RegisterInputs {
  firstName: string;
  lastName: string;
  note: string;
  email: string;
  phoneNumber: string;
}

const Register: React.FC = () => {
  const dispatch = useDispatch();
  const methods = useForm();
  const { unregister, setError, clearErrors, handleSubmit, formState: {errors, isSubmitting} } = methods;
  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const countryCodeSelect = useRef<HTMLIonSelectElement | null>(null);
  const [phoneInput, setPhoneInput] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>('+43');

  useEffect(() => {
    if (phoneInput) {
      setCountryCode('+43');
      unregister('email');
    } else {
      unregister('phoneNumber');
      setCountryCode('');
    }
  }, [phoneInput, unregister]);

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop(400);
  };

  async function submit (formData: RegisterInputs) {
      const identifierType = phoneInput ? 'phone_passcode' : 'email_passcode';
      dispatch(setRegisterInfo({cameFromLogin: false, email: formData.email, phoneNumber: formData.phoneNumber, identifierType, countryCode}));
      try {
        const clientResponse = await clientSignUp(
            identifierType,
            formData.firstName,
            formData.lastName,
            formData.note,
            formData.email,
            countryCode,
            formData.phoneNumber);

          if (clientResponse.code === 23505) { // If user exists already
            addServerErrors({ user_exist: [serverSideValidationErrors.user_exist] }, setError);
            alertOnServerError({alertType: 'error', title: 'Fehler!', text: 'Ihre Angaben sind bereits registriert. Bitte <a href="/login">loggen Sie sich ein</a> '});
          }

          else if (clientResponse?.user) {
            dispatch(setAlertActive(false));
            dispatch(setUser(clientResponse.user));
            history.push('/validate_code');
          }
        } catch (err) {
          alertOnServerError({alertType: 'error', title: 'Fehler!', text: 'Bitte aktualisieren Sie die Seite'});
        }
      }

  function alertOnServerError(alertState: AlertState) {
    dispatch(setAlertData(alertState));
    scrollToTop();
  }

  function openCountryCodeSelect () {
    if (countryCodeSelect) {
      countryCodeSelect?.current?.open();
    }
  }

  function onPhoneInputChange() {
    setPhoneInput(pi => !pi);
    clearErrors();
  }

  return (
      <IonPage>
        <Header />
        <IonContent ref={contentRef} scrollEvents={true}>
          <Alert />
          <IonGrid className='ion-align-items-space-between'>
            <IonRow className='ion-justify-content-center ion-margin-top'>
              <IonCol sizeXs="10" sizeSm='10' sizeMd="6" sizeLg="4" className='ion-text-left register-elements'>
                  <h1>Beratungsanfrage</h1>
                  <Link to='/login' className="block mb18">
                    Bereits registriert? Einloggen
                  </Link>
                  <p className='mb24'>
                  Das erste Mal hier? Bitte geben Sie uns Ihre Kontaktdaten bekannt, damit wir Sie beraten können. * Alle Felder sind verpflichtend.
                  </p>
                  <FormProvider {...methods} >
                  <form onSubmit={handleSubmit(submit)} noValidate>
                  {phoneInput ? (
                    <>
                      <IonLabel
                        color={errors?.phoneNumber && 'danger'}
                        className='input-label label-emphasis'>
                        Mobiltelefonnummer <sup>*</sup>
                      </IonLabel>
                      <IonItem
                        style={errors?.phoneNumber && redBorder}
                        className='mp-input mobile-item'>
                        <IonButton
                          fill='clear'
                          style={{border: 'none'}}
                          onClick={openCountryCodeSelect}
                          className='country-code-button'>
                          {countryCode}
                        </IonButton>
                        <IonInput
                          className='number-ioninput'
                          {...methods.register("phoneNumber", validations.phone_number)}
                          inputMode="tel"
                          type='tel'
                          min='0'
                          max='999999999'
                          name='phoneNumber'
                        ></IonInput>
                      </IonItem>
                      {errors?.phoneNumber?.message && (
                        <IonText color='danger'>
                          <small>{errors?.phoneNumber?.message}</small>
                        </IonText>
                      )}
                      <IonSelect
                        ref={countryCodeSelect}
                        onIonChange={e => setCountryCode(e.detail.value)}
                        style={{ display: 'none' }}
                        className='country-code-ionselect'>
                        {countryCodes.map(cc => (
                          <IonSelectOption key={cc.name} value={cc.dial_code}>
                            {cc.name}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </>
                  ) : (
                    <>
                      <MPInputText name='email'
                                   label='E-Mail Adresse'
                                   type='email'
                                   refName='email'
                                   required={true}
                                   serverErrorsToClean={['user_exist', 'enail']}
                                   inputClassName='mp-input ion-padding-horizontal'>
                      </MPInputText>
                      {errors?.user_exist &&
                        <IonText color='danger'>
                          <small className={errors?.user_exist ? 'danger' : ''}>
                            {errors.user_exist.message} <Link to="/login">loggen Sie sich ein</Link>
                          </small>
                        </IonText>
                      }
                    </>
                  )}
                  <span onClick={() => onPhoneInputChange()} className='mt6 mb16 f14 link-click-event'>
                     {!phoneInput ? 'Sie haben keine Email Adresse?' : 'Sie haben keine Telefonnummer?' }
                  </span>
                  <MPInputText name='firstName'
                               label='Vorname'
                               refName='first_name'
                               type="text"
                               required={true}
                               serverErrorsToClean={['firstName']}
                               inputClassName='mp-input ion-padding-horizontal'>
                  </MPInputText>
                  <MPInputText name='lastName'
                               label='Nachname'
                               refName='last_name'
                               type="text"
                               required={true}
                               serverErrorsToClean={['lastName']}
                               inputClassName='mp-input ion-padding-horizontal'>
                  </MPInputText>
                  <IonLabel
                    className='body-base-emphasis block mt32 mb16 f14'>
                    Wobei können wir Ihnen helfen?
                  </IonLabel>
                  <MPInputText name='note'
                               label='Ihr Anliegen'
                               refName='note'
                               lines='TextArea'
                               type="text"
                               required={true}
                               serverErrorsToClean={['note']}
                               inputClassName='mp-input ion-padding-horizontal note-textbox'
                               placeholder='Ich brauche ...'>
                  </MPInputText>

                  <IonButton
                    color='primary'
                    type='submit'
                    disabled={isSubmitting}
                    className='small'>
                    {'Beratung anfragen'}
                  </IonButton>
                </form>
                </FormProvider>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
  )
}

export default Register
