import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrgData } from "../server";
import { getOrgWhiteLabel, getAuthOrgWhiteLabel } from "./orgService";
import { RESPONSE_CODE } from "./shared";
import { AppThunk } from "./store";
import { alertSlice } from "./alertSlice";

export interface OrgState {
  data: OrgData;
}

const initialState: OrgState = {
  data: {},
};

export const orgSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrgData: (state, action: PayloadAction<OrgData>) => {
      state.data = action.payload;
    },
  },
});

export const { setOrgData } = orgSlice.actions;
export default orgSlice.reducer;

export const getWhiteLabel = (): AppThunk => async (dispatch, getState) => {
  try {
    const res = await getOrgWhiteLabel();

    if (res.code === RESPONSE_CODE.SUCCESS && res.organization_data) {
      dispatch(setOrgData(res.organization_data));
    } else {
      dispatch(
        alertSlice.actions.setAlertData({
          alertType: "error",
          title: "Fehler!",
          text: "Fehler beim Laden von Organisationsdatenein.",
        })
      );
    }
  } catch (err) {
    dispatch(
      alertSlice.actions.setAlertData({
        alertType: "error",
        title: "Fehler",
        text: "Fehler beim Verbinden zum Server.",
      })
    );
  }
};

export const getAuthenticatedWhiteLabel = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.auth.usertoken;
    if (!authToken) return;

    const res = await getAuthOrgWhiteLabel(authToken);

    if (res.code === RESPONSE_CODE.SUCCESS && res.organization_data) {
      dispatch(setOrgData(res.organization_data));
    } else {
      dispatch(
        alertSlice.actions.setAlertData({
          alertType: "error",
          title: "Fehler!",
          text: "Fehler beim Laden von Organisationsdatenein.",
        })
      );
    }
  } catch (err) {
    dispatch(
      alertSlice.actions.setAlertData({
        alertType: "error",
        title: "Fehler",
        text: "Fehler beim Verbinden zum Server.",
      })
    );
  }
};
