import React from "react";
import { IonItem, IonRippleEffect, IonLabel } from "@ionic/react";
import "./list.scss";

type ListIcon = {
  left_icon?: string;
  right_icon?: string;
  onClick?: any;
  children: any;
};

export const List: React.FC<ListIcon> = ({ left_icon="", right_icon="arrow-right", children, onClick }) => {
  return (
    <IonItem
      className="ion-activatable ripple-parent"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <IonLabel>
        <i className={`mp-${left_icon}`}></i>
        {children}
      </IonLabel>
      <i className={`mp-${right_icon}`}></i>
      <IonRippleEffect></IonRippleEffect>
    </IonItem>
  );
};
