import React, { useEffect, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from "./redux/store";

import { Plugins } from '@capacitor/core';
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import "antd/dist/antd.css";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "./App.scss";
import { Spin } from "antd";

import { getWhiteLabel } from "redux/orgSlice";
import { RootState } from "redux/rootReducer";

import WebSocketProvider from 'components/WebSocket/WebSocket';

import PrivateRoute from "./PrivateRoute";

import Register from "./pages/register/Register";
import Login from "./pages/login/Login";
// import Home from "./pages/home/Home";

// const Register = lazy(() => import("./pages/register/Register"));
// const Login = lazy(() => import("./pages/login/Login"));
const Home = lazy(() => import("./pages/home/Home"));

const ValidateCode= lazy(() => import("./pages/validate-code/ValidateCode"));

const Profile = lazy(() => import("./pages/profile/Profile"));
const Chat = lazy(() => import("./pages/chat/Chat"));
const Schedule = lazy(() => import("./pages/schedule/Schedule"));
const Reschedule = lazy(() => import("./pages/reschedule/Reschedule"));
const Documents = lazy(() => import("./pages/documents/Documents"));

const Meeting = lazy(() => import("./components/Meeting/Meeting"));

const SecondStepFormLoader = lazy(() => import("./pages/register-form/SecondStepFormLoader"));
const KioskPage = lazy(() => import("./pages/home/kiosk/KioskPage"));


function getFaviconEl() {
  return document.getElementById("favicon");
}

const Loader = () => {
  return <div className="middle"><Spin size="large" /></div>
}

const App: React.FC  = () => {
  const { SplashScreen } = Plugins;
  const dispatch = useDispatch();
  const favicon = getFaviconEl();
  const orgData = useSelector((state: RootState) => state.org.data);
  // const { userLoggedIn } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(getWhiteLabel());
  }, [dispatch]);

  useEffect(() => {
    if (orgData?.favicon_url) {
      (favicon as HTMLLinkElement).href = orgData?.favicon_url;
    }
  }, [favicon, orgData]);

  const hideSplash = () => {
    if (SplashScreen) {
      SplashScreen.hide();
    }
  }

  return (
    <Suspense fallback={<Loader />}>
    <PersistGate loading={<Loader />} onBeforeLift={hideSplash} persistor={persistor}>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <WebSocketProvider>
            <Switch>
              <Route path="/register" component={Register} exact={true} />
              <Route path="/login" component={Login} exact={true} />
              <Route path="/validate_code" component={ValidateCode} exact={true} />
              <Route path="/register_form" component={SecondStepFormLoader} exact={true} />
              <PrivateRoute path="/home" component={Home} exact={true} />
              <PrivateRoute path="/profile" component={Profile} exact={true} />
              <PrivateRoute path="/chat" component={Chat} exact={true} />
              <PrivateRoute path="/schedule" component={Schedule} exact={true} />
              <PrivateRoute path="/reschedule" component={Reschedule} exact={true} />
              <PrivateRoute path="/documents" component={Documents} exact={true} />

              <PrivateRoute path="/meeting/:id" component={() => <Meeting />} exact={true} />
              <PrivateRoute path="/kiosk" component={() => <KioskPage />} exact={true}/>
              
              {/* <Route path="/kiosk" component={() => userLoggedIn ? <KioskPage /> : <Redirect to={{ pathname: "/login" }} /> } exact={true}/> */}

              <Redirect from="/" to={orgData.home_path??"/home"} push={true} exact={true} />
              <Route render={() => <Redirect to={orgData.home_path??"/home"} />} />
              {/* <Route path="/" render={() => <Redirect to={orgData.home_path??"/home"} />} exact={true} /> */}
            </Switch>
            </WebSocketProvider>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </PersistGate>
    </Suspense>
  );
};

export default App;
