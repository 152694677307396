import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CalendarResponseMessage,
  MeetingRequest,
  Meeting,
} from "../server";
import { AppDispatch, AppThunk } from "./store";
import calendarInterface from "./calendarInterface";
import { showMessage } from "./alertSlice";
import { setChatMessages } from "./chatSlice";
import { setUserLoggedIn, resetToken, resetUserId } from "./authSlice";
import { RESPONSE_CODE } from "../helpers/asnycRequest";
import { RootState } from "./rootReducer";
import { parseISO } from "date-fns";

interface CalendarState extends CalendarResponseMessage {
  createMeetingError: string;
  fetchRequestsStatus: "idle" | "pending" | "failed";
  fetchRequestsError: string | null;
  saveRequestsStatus: "idle" | "pending" | "success" | "failed";
  hasUnscheduledRequest: boolean;
  upcomingMeeting: Meeting | undefined;
}

const initialState: CalendarState = {
  meeting_requests: [],
  meeting_status: [],
  meeting_todos: [],
  meeting_types: [],
  meetings: [],
  upcomingMeeting: undefined,
  hasUnscheduledRequest: false,
  message: "",
  createMeetingError: "",
  fetchRequestsStatus: "idle",
  fetchRequestsError: null,
  saveRequestsStatus: "idle",
};

export const getClientRequests = createAsyncThunk<
  any,
  string,
  { state: RootState; dispatch: AppDispatch }
>(
  "calendar/fetchUserRequests",
  async (clientId: string, { getState, dispatch }) => {
    try {
      const state = getState();
      const authToken = state.auth.usertoken;
      if (!authToken) return;

      const resp: CalendarResponseMessage = await calendarInterface.getClientRequests(
        authToken,
        clientId
      );
      if (resp.code === RESPONSE_CODE.SUCCESS) {
        if (resp.meeting_requests) {
          dispatch(setMeetingRequests(resp.meeting_requests));
        }
        if (resp.meetings) {
          dispatch(setMeeting(resp.meetings));
        }
        if (resp.meeting_todos) {
          dispatch(setMeetingTodos(resp));
        }
        if (resp.meeting_types) {
          dispatch(setMeetingTypes(resp));
        }
        if (resp.chat_messages) {
          dispatch(setChatMessages(resp));
        }
      }

      if (resp.code === RESPONSE_CODE.AUTH_ERROR) {
        logoutUser(dispatch);
        dispatch(showMessage("please login again", "warning"));
      }
    } catch (errResponse) {
      if (errResponse.code === RESPONSE_CODE.AUTH_ERROR) {
        logoutUser(dispatch);
      }
      dispatch(showMessage(errResponse.message, "danger"));
    }
  }
);

export function logoutUser(dispatch: AppDispatch) {
  dispatch(setMeetingRequests([]));
  dispatch(setMeeting([]));
  dispatch(setUserLoggedIn(false));
  dispatch(resetUserId());
  dispatch(resetToken());
}

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setMeetingRequests(state, action: PayloadAction<MeetingRequest[]>) {
      state.meeting_requests = action.payload;

      const hasUnscheduledMeeting = state.meeting_requests.some(
        (request) => !request.has_meeting
      );

      state.hasUnscheduledRequest = hasUnscheduledMeeting;
    },
    setTasks(state, action: PayloadAction<CalendarResponseMessage>) {
      state.todos = action.payload.todos;
    },
    setMeetingTodos(state, action: PayloadAction<CalendarResponseMessage>) {
      state.meeting_todos = action.payload.meeting_todos;
    },
    setMeetingTypes(state, action: PayloadAction<CalendarResponseMessage>) {
      state.meeting_types = action.payload.meeting_types;
    },
    setCreateMeetingError(state, action: PayloadAction<string>) {
      state.createMeetingError = action.payload;
    },
    setMeeting(state, action: PayloadAction<Meeting[]>) {
      state.meetings = action.payload;
      const nextMeetings = state.meetings
        ? state.meetings.filter((meeting) => {
            return (
              meeting?.end_time && parseISO(meeting?.end_time) >= new Date()
            );
          })
        : undefined;

      if (nextMeetings && nextMeetings.length > 0) {
        const nearestMeeting = nextMeetings.reduce((mr1, mr2) =>
          parseISO(mr1.start_time!) < parseISO(mr2.start_time!) ? mr1 : mr2
        );
        state.upcomingMeeting = nearestMeeting;
      } else {
        state.upcomingMeeting = undefined;
      }
    },
    setFetchRequestsStatus(
      state,
      action: PayloadAction<"idle" | "pending" | "failed">
    ) {
      state.fetchRequestsStatus = action.payload;
    },
    setFetchRequestsError(state, action: PayloadAction<null | string>) {
      state.fetchRequestsError = action.payload;
    },
    setSaveRequestsStatus(
      state,
      action: PayloadAction<"idle" | "pending" | "success" | "failed">
    ) {
      state.saveRequestsStatus = action.payload;
    },
    setHasUnscheduledRequest(state, action: PayloadAction<boolean>) {
      state.hasUnscheduledRequest = action.payload;
    },
    setUpcomingMeeting(state, action: PayloadAction<Meeting | undefined>) {
      state.upcomingMeeting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientRequests.pending, (state) => {
      state.fetchRequestsStatus = "pending";
    });
    builder.addCase(getClientRequests.fulfilled, (state) => {
      state.fetchRequestsStatus = "idle";
    });
    builder.addCase(getClientRequests.rejected, (state, action) => {
      state.fetchRequestsStatus = "failed";
      state.fetchRequestsError = action.error.message!;
    });
  },
});

export const {
  setMeetingRequests,
  setMeetingTodos,
  setTasks,
  setMeetingTypes,
  setCreateMeetingError,
  setMeeting,
  setSaveRequestsStatus,
  setHasUnscheduledRequest,
  setUpcomingMeeting,
} = calendarSlice.actions;
export default calendarSlice.reducer;

export const getMyAssignedTasks = (): AppThunk => async (
  dispatch,
  getState
) => {
  try {   
    const state = getState();
    const authToken = state.auth.usertoken;
    if (!authToken) return;

    const resp: CalendarResponseMessage = await calendarInterface.getMyTodos(
      authToken
    );
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setTasks(resp));
    } else {
      dispatch(showMessage(resp.message || "something went wrong", "warning"));
    }
  } catch (err) {
    dispatch(showMessage(err?.message??'error', "danger"));
  }
};

export const updateMeetingTodo = (
  meetingId: string,
  todoId: string,
  item: string,
  isDone: boolean
): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, user_id } = state.auth;
    if (!usertoken || !user_id) return;

    const resp: CalendarResponseMessage = await calendarInterface.updateMeetingTodos(
      usertoken,
      meetingId,
      todoId,
      item,
      isDone,
      user_id
    );
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      // dispatch(getMeetingTodos(meetingId));
    } else {
      dispatch(showMessage(resp.message || "something went wrong", "warning"));
    }
  } catch (err) {
    dispatch(showMessage(err?.message??'error', "danger"));
  }
};

export const createMeetingRequest = (
  meetingType: string,
  meetingNote: string
): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken } = state.auth;
    if (!usertoken) return;

    dispatch(setSaveRequestsStatus("pending"));
    const resp: CalendarResponseMessage = await calendarInterface.createMeetingRequest(
      usertoken,
      meetingType,
      meetingNote
    );
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      if (resp.meeting_requests) {
        dispatch(
          setMeetingRequests(
            resp.meeting_requests.concat(
              ...(state.calendar.meeting_requests ?? [])
            )
          )
        );
        dispatch(
          showMessage(
            "Ihre Eingabe wurde bestätigt.",
            "success",
            "Vielen Dank!"
          )
        );
        dispatch(setHasUnscheduledRequest(true));
        dispatch(setSaveRequestsStatus("success"));
      } else {
        dispatch(
          showMessage(
            resp.message || "Oh-oh! Etwas ist schief gelaufen",
            "warning"
          )
        );
      }
    } else {
      dispatch(setSaveRequestsStatus("failed"));
      dispatch(
        showMessage(
          resp.message || "Schlechte Anfrage oder Netzwerkfehler",
          "error"
        )
      );
    }
  } catch (err) {
    dispatch(setSaveRequestsStatus("failed"));
    dispatch(showMessage(err?.message??'error', "danger"));
    dispatch(
      setCreateMeetingError(
        err.message ?? "Oops! Fehler beim Erstellen des Meetings"
      )
    );
  }
};

export const createMeetingSpecialRequest = (
  requestType: string,
  requestObj: MeetingRequest
): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken } = state.auth;
    if (!usertoken) return;

    const resp: CalendarResponseMessage = await calendarInterface.createMeetingSpecialRequest(
      usertoken,
      requestType,
      requestObj
    );
    if (resp.code !== RESPONSE_CODE.SUCCESS) {
      if (resp.meeting_requests) {
        dispatch(setMeetingRequests(resp.meeting_requests));
      }
      dispatch(
        showMessage(
          resp.message || "Oops! Etwas ist schief gelaufen",
          "warning"
        )
      );
    } else {
    }
  } catch (err) {
    dispatch(showMessage(err?.message??'error', "danger"));
  }
};
