import {
  IonContent,
  IonPage,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonText,
  IonButton,
  IonCheckbox,
  IonItem,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import {
  parsePhoneNumber,
  parsePhoneNumberFromString,
} from "libphonenumber-js";
import { Alert, Header } from "components";
import { validations } from "helpers/validations";
import { setRegisterInfo } from "redux/authSlice";
import { setAlertData, clearAlertData } from "redux/alertSlice";
import { authOTPSms, authOTPByEmail } from "redux/authService";
interface AuthData {
  email: string;
  phoneNumber: string;
  identifierType: "phone_passcode" | "email_passcode";
  countryCode: string;
}

const Login: React.FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [sevenDaysLogin, setSevenDaysLogin] = useState(false);
  
  async function submit(props: any) {
    const validNumber = parsePhoneNumberFromString(props.phone_or_email);

    const authData: AuthData = {
      email: "",
      phoneNumber: "",
      identifierType: "phone_passcode",
      countryCode: "",
    };

    // check whether its email or phone input
    if (validNumber && validNumber.isValid()) {
      const phoneObject = parsePhoneNumber(props.phone_or_email);

      authData.identifierType = "phone_passcode";
      authData.phoneNumber = phoneObject.nationalNumber.toString();
      authData.countryCode = "+" + phoneObject.countryCallingCode;
    } else if (
      new RegExp(/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g).test(props.phone_or_email)
    ) {
      authData.identifierType = "email_passcode";
      authData.email = props.phone_or_email;
    }

    // put the data in regex
    const { email, phoneNumber, identifierType, countryCode } = authData;
    dispatch(
      setRegisterInfo({
        cameFromLogin: true,
        email,
        phoneNumber,
        identifierType,
        countryCode,
        remember: sevenDaysLogin,
      })
    );

    try {
      if (identifierType === "email_passcode") {
        await authOTPByEmail(email);
      } else if (identifierType === "phone_passcode") {
        await authOTPSms(countryCode, phoneNumber);
      }
      dispatch(clearAlertData());
      history.push("/validate_code");
    } catch (err) {
      const msg = err?.message === "User doesn't exist" ? "Die Berechtigungsnachweise konnten nicht gefunden werden. Bitte überprüfen Sie sie erneut oder registrieren Sie sich." : "Ein Fehler ist aufgetreten";
      dispatch(
        setAlertData({
          alertType: "error",
          title: "Fehler",
          text: msg,
        })
      );
    }
  }

  return (
    <IonPage>
      <Header />
      <IonContent>
        <IonGrid class="ion-align-items-space-between">
          <Alert closable={false} />
          <IonRow class="ion-justify-content-center ion-margin-top">
            <IonCol
              sizeXs="10"
              sizeSm="10"
              sizeMd="6"
              sizeLg="4"
              class="ion-text-left"
            >
              <h1>Anmelden</h1>
              <form onSubmit={handleSubmit(submit)} noValidate>
                <p className="mt16 mb24">
                  Wir senden Ihnen einen Link per E-Mail oder SMS zu, mit dem
                  Sie sich ganz einfach anmelden können.
                </p>
                <IonLabel
                  className={
                    errors?.phone_or_email?.message
                      ? "label-emphasis danger"
                      : "label-emphasis"
                  }
                >
                  E-Mail Adresse oder Mobilnummer
                </IonLabel>
                <IonInput
                  {...register("phone_or_email", validations.phone_or_email)}
                  name="phone_or_email"
                  class="mp-input ion-padding-horizontal mt6"
                  aria-required="true"
                  aria-describedby="Phone or email"
                  autofocus={true}
                  type="email"
                ></IonInput>
                <IonText class="block help-text mt6">
                  <small
                    className={errors?.phone_or_email?.message ? "danger" : ""}
                  >
                    {" "}
                    E-Mail Adresse oder Mobiltelefonnummer, mit der Sie bei uns
                    registriert sind.{" "}
                    {errors?.phone_or_email ? (
                      <Link to="/register">Noch nicht registriert?</Link>
                    ) : (
                      ""
                    )}
                  </small>
                </IonText>
                <div className="remember-me mt24 flex">
                  <IonItem class="remember-item ion-no-padding no-ripple">
                    <IonCheckbox
                      {...register('remember')}
                      name="remember"
                      class="core"
                      mode="md"
                      color="dark"
                      checked={sevenDaysLogin}
                      onIonChange={(e) => setSevenDaysLogin(e.detail.checked)}
                    />
                    <IonLabel class="checkbox-label">
                      Zugang für 7 Tage merken
                    </IonLabel>
                    <span className="ion-margin-start"></span>
                  </IonItem>
                </div>
                <IonButton color="primary" type="submit" className="small">
                  {"Link zur Anmeldung anfordern"}
                </IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
