import {
  AccountsApi,
  AccountResponseMessage,
  OrganizationUserPersonalData,
} from "../server";
import { handleErrorResponse } from "./shared";

const account = new AccountsApi();

export const clientSignUp = async (
  identifierType: string,
  firstName: string,
  lastName: string,
  note: string,
  email?: string,
  phoneCountryCode?: string,
  phoneNumber?: string,
  socialSecurityNo?: string,
  language?: string
): Promise<AccountResponseMessage> => {
  try {
    const { data } = await account.clientSignUp(
      identifierType,
      firstName,
      lastName,
      note,
      email,
      phoneCountryCode,
      phoneNumber,
      socialSecurityNo,
      language
    );
    return data;
  } catch (error) {
    return handleErrorResponse(error);
  }
};

export const updatePersonalUserData = async (
  authorization: string,
  userData: OrganizationUserPersonalData
): Promise<AccountResponseMessage> => {
  try {
    const { data } = await account.updatePersonalUserData(
      authorization,
      userData
    );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
};

export async function updateUserStatus(authToken: string, status: string) {
  try {
    const { data } = await account.updateUserStatus(authToken, status);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}
