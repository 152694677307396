import { AccountsApi, AccountResponseMessage } from "../server";
import { handleErrorResponse } from './shared';

const account = new AccountsApi();

export const getOrgWhiteLabel = async(): Promise<AccountResponseMessage> => {
  try {
		 const { data } = await account.whiteLabel();
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
};

export const getAuthOrgWhiteLabel = async(authToken: string): Promise<AccountResponseMessage> => {
  try {
		 const { data } = await account.authenticatedWhiteLabel(authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
};
