import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from './store';

export type AlertMessageType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'error';

export interface AlertState {
  active?: boolean;
  alertType: AlertMessageType,
  title: string;
  text: string;
}

const initialState: AlertState = {
  active: false,
  alertType: 'success',
  title: '',
  text: ''
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlertData(state, action: PayloadAction<AlertState>) {
      state.alertType = action.payload.alertType;
      state.title = action.payload.title;
      state.text = action.payload.text;
      state.active = true;
    },
    setAlertActive(state, action: PayloadAction<boolean>) {
      state.active = action.payload
    },
    clearAlertData(state) {
      state.active = initialState.active;
      state.alertType = initialState.alertType;
      state.title = initialState.title;
      state.text = initialState.text;
    }
  }
})

export const showMessage = (content: string, type: AlertMessageType, title: string = ""): AppThunk => async (dispatch) => {
  const alert: AlertState = {
    active: true,
    alertType: type,
    text: content,
    title: title,
  };
  dispatch(setAlertData(alert));
  // setTimeout(() => dispatch(setAlertActive(true)), 2000);
};

export const { setAlertData, setAlertActive, clearAlertData } = alertSlice.actions;
export default alertSlice.reducer;