import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./redux/rootReducer";

const PrivateRoute: React.FC<RouteProps> = ({
  component,
  ...rest
}) => {
  const { userLoggedIn } = useSelector((state: RootState) => state.auth);
  
  if (!userLoggedIn) {
    return <Redirect to={{ pathname: "/login" }} />;
  }

  return <Route {...rest} component={component} />;
};

export default PrivateRoute;
