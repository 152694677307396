import React from 'react'
import './box-container.scss';
import { Meeting } from "server";
import { useHistory } from "react-router-dom";
import { GhostButton } from '../GhostButton/GhostButton';
import { parseISO } from 'date-fns';

type BoxProps = { 
  children?: any; 
  title?: string; 
  subtitle?: string; 
  activeVariant?: string, 
  meetingBox?: boolean, 
  meeting?: Meeting 
}

export const BoxContainer: React.FC<BoxProps> = ({ title, subtitle, children, activeVariant, meetingBox, meeting }) => {
  const history = useHistory();
  
  function meetingIsNow() {
    return meeting?.start_time && meeting.end_time && (parseISO(meeting?.start_time) < new Date() && parseISO(meeting?.end_time) > new Date())
  }

  return (
    <div className={`box-container ${activeVariant} ${title ? '' : 'no-border'}`}>
      {title &&
        <div className="box-title">
          {title}
          <div className="subtitle">
            {meetingIsNow()
              ? (<>Findet jetzt statt!</>)
              : (<> {subtitle} </>)
            }
          </div>
          {meetingBox && subtitle && (
            <div className="btn" >
              <GhostButton onClick={() => history.push(`/meeting/${meeting?.id}`)} iconClass="mp-video"> Zur Besprechung gehen </GhostButton>
            </div>)}
        </div>
      }

      <div className={`box-content`}>{children}</div>
    </div>
  )
}