import React, { useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { redBorder } from "helpers/validations";
import { useFormContext } from "react-hook-form";
import countryCodes from "static/countryCodes.json";

interface PhoneInputProps {
  required?: string;
  defaultCountryCode?: string;
}

export const PhoneInput = ({
  required,
  defaultCountryCode = "",
}: PhoneInputProps) => {
  const [phoneCode, setPhoneCode] = useState<string>(defaultCountryCode);
  const countryCodeSelect = useRef<HTMLIonSelectElement | null>(null);
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setValue("countryExt", phoneCode);
  }, [phoneCode, setValue]);

  function openCountryCodeSelect() {
    if (countryCodeSelect) {
      countryCodeSelect?.current?.open();
    }
  }

  return (
    <>
      <IonLabel
        color={errors?.phoneNumber && "danger"}
        className="input-label label-emphasis"
      >
        Mobiltelefonnummer {required && <sup>*</sup>}
      </IonLabel>

      <IonItem
        style={errors?.phoneNumber && redBorder}
        className="mp-input mobile-item"
      >
        <IonButton
          fill="clear"
          style={{ border: "none" }}
          onClick={openCountryCodeSelect}
          className="country-code-button"
        >
          {phoneCode}
        </IonButton>

        <IonInput
          class="number-ioninput"
          {...register("phoneNumber", { maxLength: 12, required: required })}
          type="tel"
          maxlength={12}
          name="phoneNumber"
          enterkeyhint="next"
          aria-invalid={errors?.phoneNumber ? "true" : "false"}
        ></IonInput>

        <IonSelect
          {...register("countryExt", { required: required })}
          ref={countryCodeSelect}
          value={phoneCode}
          onIonChange={(e) => {
            setPhoneCode(e.detail.value);
          }}
          style={{ display: "none" }}
          class="country-code-ionselect"
          okText="OK"
          cancelText="Abbrechen"
        >
          {countryCodes.map((cc) => (
            <IonSelectOption key={cc.name} value={cc.dial_code}>
              {cc.name} ({cc.dial_code})
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
      {errors?.phoneNumber?.message && (
        <IonText color="danger">
          <small>{errors?.phoneNumber?.message}</small>
        </IonText>
      )}
    </>
  );
};
