import React from 'react';
import { IonRow, IonCol } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertState, setAlertActive } from 'redux/alertSlice';
import './alert.scss';
import { RootState } from 'redux/rootReducer';

type AlertProps = { children?: any; closable?: boolean; }

export const Notification:React.FC<AlertProps> = ({children, closable = true}) => {
  const alert: AlertState = useSelector((state: RootState) => state.alert)
  const dispatch = useDispatch();

  function closeAlert(){
    if (closable) {
      dispatch(setAlertActive(false));
    }
  }
  
  if(!alert.active) return <></>
  return (
    <div onClick={closeAlert} className='notification'>
      <IonRow  className={`notification-${ alert.alertType } ion-justify-content-center`}>
        <IonCol className={closable?'':'ion-hide'}></IonCol>
        
        <IonCol sizeXs="10" sizeSm='10' sizeMd="6" sizeLg="4" className="w-100">
          <strong>{alert.title}</strong>
          <p dangerouslySetInnerHTML={{__html: alert.text}}></p>
          {children}
        </IonCol>
        
        <IonCol className={closable?'closable':'ion-hide'}>
          <div className="ion-text-right"><i className="mp-close"></i></div>
        </IonCol>
      </IonRow>
    </div>
  )
}
