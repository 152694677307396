import { Button } from 'antd'
import React from 'react'
import './GhostButton.scss'

export const GhostButton: React.FC<{ onClick: () => void, iconClass?: string }> = ({ onClick, iconClass = 'mp-arrow-right', children }) => {
    return (
        <Button className="button" onClick={onClick}>
            <div className='content'>
                <span>
                    {children}
                </span>

                <i className={`icon ${iconClass}`} />
            </div>
        </Button>
    )
}
