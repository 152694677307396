import { combineReducers } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';

import accountSliceReducer from './accountSlice';
import alertSliceReducer from './alertSlice';
import authSliceReducer from './authSlice';
import calendarSliceReducer from './calendarSlice';
import chatSliceReducer from './chatSlice';
import orgSliceReducer from './orgSlice';

const rootReducer = combineReducers({
    account: accountSliceReducer,
    org: orgSliceReducer,
    alert: alertSliceReducer,
    auth: authSliceReducer,
    calendar: calendarSliceReducer,
    chat: chatSliceReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootReducer;
