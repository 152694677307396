import { AuthenticationApi,	AuthenticationResponse, AccountResponseMessage } from "../server";
import { handleErrorResponse } from './shared';

const auth = new AuthenticationApi();

export const authenticateClientOTP = async (
	passcode: string
): Promise<AuthenticationResponse> => {
  try {
		 const { data } = await auth.authenticateClientOTP(passcode);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
};

export const authenticateUserOTP = async (passcode: string, remember?: boolean) => {
	try {
		const { data } = await auth.authenticateUserOTP(passcode, remember)
		return data;
	} catch (e) {
		return handleErrorResponse(e);
	}
}

export const authOTPByEmail = async (
	email: string
): Promise<AccountResponseMessage> => {
  try {
		 const { data } = await auth.authOTPEmail(
			email
		 );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
};


export const authOTPSms = async (
	countryCode: string,
	phone: string
): Promise<AccountResponseMessage> => {
  try {
		 const { data } = await auth.authOTPSms(
			countryCode,
			phone
		 );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
};