import {
  CalendarApi,
  CalendarResponseMessage,
  MeetingNote,
  MeetingChatMessage,
  MeetingRequest,
} from "../server";
import { addYears, startOfToday, subMonths } from "date-fns";

import { handleErrorResponse } from "./shared";

const calendarApi = new CalendarApi();

async function getOpenMeetingRequests(
  authToken: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getOpenMeetingRequests(authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

export async function getMyAppointments(
  authToken: string,
  userId?: string
): Promise<CalendarResponseMessage> {
  try {
    if (!userId) {
      throw new Error("No UserID - was the user not authenticated?");
    }
    const today_start = startOfToday();
    const a_year_ahead = addYears(new Date(), 1);

    const { data } = await calendarApi.getMeetingsForUser(
      authToken,
      userId,
      today_start.toISOString(),
      a_year_ahead.toISOString()
    );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getMeetingChat(
  authToken: string,
  meetingId: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingChat(authToken, meetingId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getMeetingNotes(
  authToken: string,
  meetingId: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingNotes(authToken, meetingId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getMyTodos(
  authToken: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMyTodos(authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}


async function getMeetingToDo(
  authToken: string,
  meetingId: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingTodos(meetingId, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function addMeetingNote(
  authToken: string,
  meetingId: string,
  note: string
): Promise<CalendarResponseMessage> {
  try {
    const meetingNote: MeetingNote = {
      note: note,
      meeting_id: meetingId,
    };
    const { data } = await calendarApi.addMeetingNote(authToken, meetingNote);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function updateMeetingTodos(
  authToken: string,
  meetingId: string,
  todoId: string,
  item: string,
  isDone: boolean,
  assignee: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.updateMeetingTodos(
      meetingId,
      todoId,
      authToken,
      item,
      isDone,
      assignee
    );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getChatMessages(
  authToken: string,
  meetingId: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingChat(authToken, meetingId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getPagedChatHistoryForUser(
  authToken: string,
  clientId: string,
  page = 0
): Promise<CalendarResponseMessage> {
  try {
    let fromDate: Date = subMonths(new Date(), (page + 1) * 3);
    let toDate: Date = subMonths(new Date(), page * 3);
    const { data } = await calendarApi.getChatHistoryForUser(
      authToken,
      clientId,
      fromDate.toISOString(),
      toDate.toISOString()
    );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getDeltaChatHistoryForUser(
  authToken: string,
  clientId: string,
  fromDate?: Date
): Promise<CalendarResponseMessage> {
  try {
    const toDate: Date = new Date();
    if (!fromDate) {
      fromDate = subMonths(new Date(), 6);
    }
    // console.log(fromDate.toISOString() + " " + toDate.toISOString());
    const { data } = await calendarApi.getChatHistoryForUser(
      authToken,
      clientId,
      fromDate.toISOString(),
      toDate.toISOString()
    );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function sendChatMessage(
  authToken: string,
  chatObj: MeetingChatMessage
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.addMeetingChatMessage(
      authToken,
      chatObj
    );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function deleteMeetingChatMessage(
  authToken: string,
  id: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.deleteMeetingChatMessages(authToken, id);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}
async function updateMeetingChatMessage(
  authToken: string,
  chatObj: MeetingChatMessage
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.updateMeetingChatMessage(
      authToken,
      chatObj.id || "",
      chatObj
    );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}
async function updateMeetingNote(
  authToken: string,
  noteId: string,
  noteText: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.updateMeetingNote(
      authToken,
      noteId,
      noteText
    );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getMeetingTypes(
  authToken: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingTypes(authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function addMeetingType(
  authToken: string,
  name: string,
  slices: number
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.addMeetingType(
      authToken,
      name,
      0,
      slices,
      0
    );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function deleteMeetingType(
  authToken: string,
  id: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.deleteMeetingType(id, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function createMeetingRequest(
  authToken: string,
  meetingType: string,
  meetingNote: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.createMeetingRequest(
      authToken,
      meetingType,
      meetingNote
    );

    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}
async function getClientRequests(
  authToken: string,
  clientId: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getClientRequests(authToken, clientId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}
async function createMeetingSpecialRequest(
  authToken: string,
  requestType: string,
  requestObj: MeetingRequest
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.createMeetingSpecialRequest(
      authToken,
      requestType,
      requestObj
    );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

export const updateMeetingRequest = async (
  authorization: string,
  request_id: string,
  startTime?: string,
  endTime?: string,
  meetingType?: string,
  meetingID?: string,
  note?: string
): Promise<CalendarResponseMessage> => {
  try {
    const { data } = await calendarApi.updateMeetingRequest(
      authorization,
      request_id,
      startTime,
      endTime,
      meetingType,
      meetingID,
      note
    );
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
};

async function setParticipantStatus(authToken: string, meetingId: string, participantId: string, statusId: number): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.updateMeetingParticipantRSVP(authToken, meetingId, participantId, statusId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

const calendarInterface = {
  getOpenMeetingRequests,

  // Notes:
  getMeetingNotes,
  addMeetingNote,
  updateMeetingNote,

  // ToDos:
  getMyTodos,
  getMeetingToDo,
  updateMeetingTodos,

  // Chat
  getMeetingChat,
  sendChatMessage,
  getChatMessages,
  getPagedChatHistoryForUser,
  getDeltaChatHistoryForUser,
  deleteMeetingChatMessage,
  updateMeetingChatMessage,

  // Meeting Types:
  addMeetingType,
  getMeetingTypes,
  deleteMeetingType,

  createMeetingRequest,
  updateMeetingRequest,

  getClientRequests,
  createMeetingSpecialRequest,

  setParticipantStatus
};
export default calendarInterface;
